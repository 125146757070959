<template>
  <div class="result">
    <div class="fix-order">
      <img
        src="https://qiniu.zhiyunys.com/img/order-check.png"
        @click="$router.push('/order')"
      />
    </div>
    <div class="header" style="background: #dfc9a1; width: 100%; z-index: 999">
      <div class="fix_newFriend">
        <div class="fix_left">
          <div class="headImg">
            <img src="../assets/img/head.png" />
          </div>
          <div class="middle_txt">
            <p class="txt1">国学文化</p>
            <p class="txt2">请添加大师微信免费咨询</p>
          </div>
        </div>
        <!-- <div class="add_btn" @click.stop="showPopup = true">请教添加</div> -->
        <div class="add_btn" @click.stop="handle_show()">请教添加</div>
      </div>
    </div>
    <div style="padding: 20px">
      <img
        class="top"
        src="https://qiniu.zhiyunys.com/zgjiemeng/resultPayed_banner.png"
      />
      <div class="info">
        <img
          class="title"
          src="https://qiniu.zhiyunys.com/zgjiemeng/result_info_title.png"
        />
        <p>
          梦境:<span>{{ info.dream }}</span>
        </p>
        <p>
          姓名:<span>{{ info.userName }}({{ info.userSex }})</span>
        </p>
        <p>
          生日:<span>{{ info.usergBirthTime }}</span>
        </p>
        <p>
          时间:<span>{{ info.time }}</span>
        </p>
        <p>
          过程:<span>{{ info.course }}</span>
        </p>
        <p>
          关注:<span>{{ info.concern }}</span>
        </p>
        <p>
          八字:<span>{{ info.horoscope }}</span>
        </p>
      </div>
      <div class="res">
        <img
          class="title"
          src="https://qiniu.zhiyunys.com/zgjiemeng/resultPayed_title.png"
        />
        <div class="content">
          <p v-for="(item, index) in result" :key="index">
            <span>{{ item.des }}</span>
          </p>
        </div>
      </div>
      <div class="add_box">
        <img class="title" src="../assets/img/sfbt.png" />
        <div class="content">
          <p class="des">
            李师傅，生于命理世家，十余年从业经验善于
            梦境解析、财运分析、风水布局、姻缘合婚等,
            精于玄空风水、八字精批、财运分析、周易象
            等;担任海内外多家著名企业的御用风水师，立 志宏扬正统国粹。
          </p>
          <img
            style="width: 100%; margin: 10px 0;"
            mode="widthFix"
            class=""
            src="../assets/img/sffx.png"
          />
          <div class="card_box">
            <div class="bb_row">
              <div>
                <p class="wx">微信号:gxjdwh888</p>
                <p class="wx_des">(长按复制) 拷贝,或直接扫描右侧二维码</p>
              </div>
              <div class="code_img">
                <img src="../assets/img/code.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 加好友弹窗 -->
    <van-popup v-model="showPopup" :closeable="true" :round="true">
      <div class="popup_box">
        <div class="qr_box">
          <img mode="widthFix" src="../assets/img/code2.png" />
          <p class="code_txt">微信号:gxjdwh888(长按复制)</p>
        </div>
        <img class="yp_item" mode="widthFix" src="../assets/img/yq.png" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import api from "@/apis/order";
export default {
  data() {
    return {
      payWay: 1,
      odd: this.$route.query.odd,
      info: {},
      result: [],
      showPopup: false,
    };
  },
  async created() {
    const res = await api.getOrder({
      odd: this.odd,
      orderType: "周公解梦",
    });
    this.info = res[0];
    if (this.info.status === 0) {
      this.$toast("该订单尚未支付，请点击右侧订单查询！");
      return;
    }
    const content = await api.getContent({
      dream: this.info.dream,
    });
    this.result = content.result;
    console.log(res);
  },
  mounted() {
    console.log(document.getElementsByClassName("result")[0].clientHeight);
  },
  methods: {
    async handle_show() {
      this.$nextTick();
      this.showPopup = true;
      console.log("showPopup", this.showPopup);
    },
  },
};
</script>
<style>
.el-radio__inner {
  background: transparent;
  border-color: #a6967a;
}
</style>
<style lang="less" scoped>
@plugin '../styles/var/px2rem.js';
.result {
  background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/main-bg.png");
  background-size: contain;
  position: relative;
  font-size: 0.187rem;
  overflow: auto;
  // padding: px2rem(37) px2rem(33) 0;
  .top {
    display: block;
    height: px2rem(160);
    margin: auto;
  }
  .info {
    height: px2rem(648);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/result_info.png")
      no-repeat;
    background-size: 100% 100%;
    padding: px2rem(94) px2rem(67) 0;
    margin-bottom: px2rem(43);
    .title {
      height: px2rem(54);
      display: block;
      margin: 0 auto px2rem(46);
    }
    p {
      margin-bottom: px2rem(43);
      font-size: px2rem(32);
      color: #6a470f;
      span {
        font-size: px2rem(26);
        margin-left: px2rem(18);
        color: rgba(106, 71, 15, 0.9);
      }
    }
  }
  .res {
    height: px2rem(652);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/result_bg.png")
      no-repeat;
    background-size: 100% 100%;
    margin-bottom: px2rem(44);
    padding: px2rem(46) px2rem(67);
    .title {
      height: px2rem(54);
      display: block;
      margin: 0 auto px2rem(46);
    }
    .content {
      height: px2rem(500);
      overflow: auto;
      p {
        line-height: 2;
        font-size: px2rem(26);
        // filter: blur(px2rem(5));
        color: #6a470f;
        font-weight: 500;
        span {
          color: rgba(106, 71, 15, 0.9);
          font-weight: 400;
        }
      }
    }
  }
  .add_box{
    height: px2rem(582);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/result_bg.png")
      no-repeat;
    background-size: 100% 100%;
    margin-bottom: px2rem(44);
    padding: px2rem(46) px2rem(67);
    .title {
      height: px2rem(54);
      display: block;
      margin: 0 auto px2rem(46);
    }
    .content {
      // height: px2rem(500);
      // overflow: auto;
      .des {
        line-height: 1.4;
        font-size: px2rem(26);
        // padding: 0 20px;
        // filter: blur(px2rem(5));
        color: #6a470f;
        font-weight: 500;
        span {
          color: rgba(106, 71, 15, 0.9);
          font-weight: 400;
        }
      }
    }
  }
  .header {
    position: sticky;
    top: 0;
    padding: 5px 0;
    margin-bottom: 30px;
  }

  .fix_newFriend {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
    padding: 10px 15px;
    background: url("../assets/img/zu.png") no-repeat;
    background-size: 100% 100%;
    z-index: 999;
    .fix_left {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: start;
    }

    .headImg {
      width: 50px;
      height: 50px;
      background: #ffffff;
      border-radius: 50%;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .middle_txt {
      color: #784822;

      .txt1 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .txt2 {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .add_btn {
      font-size: 13px;
      font-weight: normal;
      color: #fff;
      padding: 11px;
      background: #b11d1d;
      border-radius: 18px;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(0deg, #bf1e21, #e72828);
      box-shadow: 0px 4px 4px 0px rgba(175, 105, 43, 0.85);
      border-radius: 4px;
    }
  }
  .popup_box {
    background-color: #feeec1;
    // padding: 30px 0;
    background: url("../assets/img/bg.png") no-repeat;
    background-size: 100% 100%;
    width: 9rem;
    height: 15rem;
    position: relative;

    .popup_bg {
      // width: 90%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .qr_box {
      width: 300px;
      height: 270px;
      background-color: #fff;
      text-align: center;
      margin: 0 auto;
      padding: 15px 0;
      position: absolute;
      bottom: 6%;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 250px;
        height: 250px;
      }

      .code_txt {
        font-size: 14px;
        font-weight: bold;
        color: #a9171a;
      }
    }
    .yp_item {
      position: absolute;
      bottom: 4%;
      right: 3%;
      width: 60px;
      height: 50px;
    }
  }
  .card_box {
    padding: 20px;
    background: url("../assets/img/sfwx.png") no-repeat;
    background-size: 100% 100%;

    .sf_txt {
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: #fcfbfa;
      line-height: 24px;
      background: linear-gradient(
        -10deg,
        #fbe09d 0%,
        #fcdc8e 17.7734375%,
        #fbe7d3 44.3115234375%,
        #fcdc8e 71.9970703125%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .bb_row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      // padding: 0 20px;
      .wx {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 10px;
      }

      .wx_des {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.57;
        margin-top: 10px;
      }

      .code_img {
        width: 52px;
        height: 52px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>