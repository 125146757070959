<template>
  <div class="main" @click="showmjResult = false">
    <div class="notice">
      <div class="content" :style="{ margin: `0 ${width}px` }">
        我平台所有测试仅供娱乐，不得作为生活、工作之指导。我平台产品拒绝向未成年人提供服务，如未成年人使用切造成一切后果由其监护人自行承担。本网页数据安全由阿里云提供，严格按照法律法规和用户协议对您的信息进行全方位的保护，请放心使用！
      </div>
    </div>
    <div class="tips" v-if="showTips">
      <div class="order-desc">您有进行中的测算订单喔</div>
      <div style="display: flex; align-items: center">
        <div class="go-order" @click="jumpToOrder">立即查看</div>
        <div class="close-tips" @click="showTips = false">关闭</div>
      </div>
    </div>
    <div class="banner"></div>
    <div class="mjzl">
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/mjzl_title.png"
        class="title"
      />
      <div class="form">
        <p class="mj">
          <label>梦境:</label>
          <input
            type="text"
            v-model="params.dream"
            placeholder="请描述您的梦境关键字(如:蛇)"
          />
          <!--  @change="getDreamReslut"-->
          <!-- <i @click="showmjResult = !showmjResult"></i>
          <mjResult v-show="showmjResult" :dataList="dataList" @on-checked-item="onCheckedItem"></mjResult> -->
        </p>
        <p class="xm">
          <label>姓名:</label>
          <input
            type="text"
            v-model="params.userName"
            placeholder="请输入您的姓名"
          />
          <button>
            <span
              :class="`${params.userSex === '男' ? 'active' : ''}`"
              style="border-radius: 15px 0 0 15px; border-right: unset"
              @click.stop="params.userSex = '男'"
              >男</span
            >
            <span
              :class="`${params.userSex === '女' ? 'active' : ''}`"
              style="border-radius: 0 15px 15px 0; border-left: unset"
              @click.stop="params.userSex = '女'"
              >女</span
            >
          </button>
        </p>
        <p class="sr" @click.stop="showPopup = true">
          <label>生日:</label>
          <input
            type="text"
            v-model="params.userBirthTime"
            placeholder="请选择您的生日"
            disabled
          />
          <i></i>
        </p>
        <p class="sj">
          <label>时间:</label>
          <van-radio-group v-model="params.time">
            <van-radio name="刚睡">
              刚睡
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
            <van-radio name="深夜">
              深夜
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
            <van-radio name="凌晨">
              凌晨
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
            <van-radio name="白天">
              白天
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
          </van-radio-group>
        </p>
        <p class="gc">
          <label>过程:</label>
          <van-radio-group v-model="params.course">
            <van-radio name="混乱">
              混乱
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
            <van-radio name="断续">
              断续
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
            <van-radio name="清醒">
              清醒
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
            <van-radio name="深刻">
              深刻
              <template #icon="props">
                <div
                  :class="`${props.checked ? 'active' : ''}`"
                  class="uncheck-radio"
                ></div>
              </template>
            </van-radio>
          </van-radio-group>
        </p>
        <p class="gz">
          <label>关注:</label>
          <span class="list">
            <span class="cards">
              <span
                :class="`${params.concern === item ? 'active' : ''}`"
                v-for="(item, index) in gzList"
                :key="index"
                @click="onHandleGz(item)"
                >{{ item }}</span
              >
            </span>
          </span>
        </p>
      </div>
    </div>
    <div class="commit-con">
      <span
        ><i
          :class="`${selectProtocol ? 'active' : ''}`"
          @click.stop="selectProtocol = !selectProtocol"
        ></i
        ><a href="javascript:;"
          >同意<a @click="$router.push('/protocol')">用户</a
          ><a @click="$router.push('/wrapper')">隐私</a>协议</a
        ></span
      >
      <p class="ttips">测试部分免费，获取报告或解锁全部内容时，需付费查看</p>
      <button class="submit" @click.stop="oneiromancy">马上解梦</button>
    </div>
    <div class="jmdyy">
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/jmdyy_title.png"
        class="title"
      />
      <span>梦是另一种虚幻却真实的体人生验</span>
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/jmdyy_1.png"
        class="jmdyy-img"
      />
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/jmdyy_2.png"
        class="jmdyy-img"
      />
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/jmdyy_3.png"
        class="jmdyy-img"
      />
    </div>
    <div class="mjkr">
      <img src="../assets/img/improve/mjkr-title.png" class="title" />
      <div class="mjkr-img">
        <div>
          <img src="../assets/img/improve/image01.png" />
          <p>每天做梦，不知到底何寓意，不知是好是坏。</p>
        </div>
        <div>
          <img src="../assets/img/improve/image02.png" />
          <p>想找人解梦，不知道去哪里找。</p>
        </div>
      </div>
      <div class="mjkr-img">
        <div>
          <img src="../assets/img/improve/image03.png" />
          <p>线下门店解梦师傅天价收费。</p>
        </div>
        <div>
          <img src="../assets/img/improve/image04.png" />
          <p>解梦不准，导致感情、工作、生活、处处遇阻。</p>
        </div>
      </div>
      <img src="../assets/img/improve/mjzz.png" class="mjzz" />
    </div>
    <div class="dszrjm">
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/dszrjm_title.png"
        alt=""
        class="title"
      />
      <span>通过心理学、易学等多角度分析</span>
      <p>
        根据梦者所提供的梦境信息,人工分析梦境的主体和主要内容代表的特殊涵义,让梦者了解梦境代表的意义,同时针对梦者的命格和梦境内容,提醒梦者近期可能面临的境况,为梦者提供专业的建议。
      </p>
    </div>
    <div class="bzjm"></div>
    <div class="bzjm_con">
      <p>
        根据梦者所提交的梦境信息,结合梦者的生辰八字,人工进行传统生辰八字排盘大运分析。八字中包含了人一生的爱情、事业、钱财等全部的信息只有以生辰八字作为解梦基石和参考依据,才能确保解梦结果的准确性，只有纳入生辰八字的解梦才是科学解梦。
      </p>
    </div>
    <div class="xyjm">
      <img src="../assets/img/improve/xyjm-title.png" alt="" class="title" />
      <img
        src="../assets/img/improve/four.png"
        alt=""
        class="jm"
        style="width: 90%"
      />
      <img src="../assets/img/improve/jm1.png" alt="" class="jm" />
      <img src="../assets/img/improve/jm2.png" alt="" class="jm" />
      <div class="jms">
        <div>
          <img src="../assets/img/improve/jm3.png" />
          <p>财运双收</p>
        </div>
        <div>
          <img src="../assets/img/improve/jm4.png" />
          <p>感情顺利</p>
        </div>
        <div>
          <img src="../assets/img/improve/jm5.png" />
          <p>升职加薪</p>
        </div>
      </div>
    </div>
    <div class="ys">
      <img src="../assets/img/improve/ys-title.png" alt="" class="title" />
      <img src="../assets/img/improve/ys.png" alt="" class="cont" />
    </div>
    <!-- <div class="mzzsal">
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/mzzsal_title.png"
        alt=""
        class="title"
      />
      <span>通过心理学、易学等多角度分析</span>
      <van-swipe :autoplay="3000" @change="onChange">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            <div
              class="custom-indicator-item"
              :class="`${current === _index ? 'active' : ''}`"
              v-for="(img, _index) in images"
              :key="_index"
            ></div>
          </div>
        </template>
      </van-swipe>
    </div> -->
    <div class="mydt">
      <img
        src="https://qiniu.zhiyunys.com/zgjiemeng/main/mydt_title.png"
        alt=""
        class="title"
      />
      <div class="mydt-notice" id="mydtContent">
        <div class="mydt-content">
          <div class="mydt-content-item">
            <label for="time">2022-10-29</label>
            <span>康女士：梦见男朋友出轨并看见女的长</span>
          </div>
          <div class="mydt-content-item">
            <label for="time">2022-11-03</label>
            <span>赵先生：梦见巨大的白色蟒蛇</span>
          </div>
          <div class="mydt-content-item">
            <label for="time">2022-11-22</label>
            <span>余女士：梦见自己受伤流了很多血</span>
          </div>
          <div class="mydt-content-item">
            <label for="time">2022-12-08</label>
            <span>余女士：梦见自己受伤流了很多血</span>
          </div>
          <div class="mydt-content-item">
            <label for="time">2022-10-29</label>
            <span>康女士：梦见男朋友出轨并看见女的长</span>
          </div>
          <div class="mydt-content-item">
            <label for="time">2022-11-03</label>
            <span>赵先生：梦见巨大的白色蟒蛇</span>
          </div>
          <div class="mydt-content-item">
            <label for="time">2022-11-22</label>
            <span>余女士：梦见自己受伤流了很多血</span>
          </div>
          <div class="mydt-content-item">
            <label for="time">2022-12-08</label>
            <span>余女士：梦见自己受伤流了很多血</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="jptj">
      <img src="../assets/img/improve/jptj-title.png" class="title" />
      <div class="cont">
        <img @click="jumpTpUrl('zjr')" src="../assets/img/improve/zjr.png" />
        <img @click="jumpTpUrl('zwds')" src="../assets/img/improve/zwds.png" />
      </div>
    </div> -->
    <!-- yc.zhiyunys.com -->
    <!-- <p class="company">广东智运网络科技有限公司</p> -->
    <!-- <p class="company">广州风吹雨网络科技有限公司</p>
    <p class="company">粤ICP备2022024711号-1</p> -->
    <!-- 百度 jr.qm1234.cn 不要 mzzsal -->
    <!-- <p class="company">南京圣亿网络科技有限公司温州第一分公司</p> -->
    <!-- 百度 yc.hfyuyejy.cn 不要 mzzsal -->
    <!-- <p class="company">合肥钰烨教育咨询有限公司第二分公司</p> -->
    <!-- https://zg.xingela.cn/ -->
    <!-- <p class="company">盐城辛格拉网络科技有限公司</p> -->
    <!-- zg.shwanpt.cn -->
    <!-- <p class="company">莆田市秀屿区顺宛信息咨询有限公司杭州分公司</p> -->
    <!-- 神马 zg.banghai.top 不要 mzzsal -->
    <!-- <p class="company">合肥起量快信息技术咨询有限公司福州市台江区分公司</p> -->
    <!-- zyys.zhan36.cn -->
    <!-- <p class="company">南京展智电子商务有限公司云南分公司</p> -->
    <!-- yc.qtddd.cn -->
    <!-- <p class="company">合肥对对对网络信息咨询有限公司江西分公司</p> -->
    <!-- zg.fygsi.cn -->
    <p class="company">合肥绵获生物科技有限公司滁州分公司</p> 
    <van-popup v-model="showPopup" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datehour"
        title=""
        :columns-order="['year', 'month', 'day', 'hour']"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        :visible-item-count="5"
        :item-height="`1.06rem`"
        @confirm="confirmDateTime"
        @cancel="cancelDateTime"
      />
    </van-popup>
  </div>
</template>

<script>
import api from "@/apis/order";
// import mjResult from "@/components/mjResult.vue"
export default {
  // components:{mjResult},
  data() {
    return {
      isToBack: false,
      width: 0,
      count: 2204362,
      timer: null,
      showTips: false,
      gzList: [
        "运势",
        "时机",
        "生意",
        "投资",
        "家庭",
        "姻缘",
        "情变",
        "出行",
        "疾病",
        "工作",
        "学业",
        "房产",
      ],
      selectProtocol: true,
      current: 0,
      images: [
        require("@/assets/img/main/al_1.png"),
        require("@/assets/img/main/al_2.png"),
        require("@/assets/img/main/al_3.png"),
      ],
      params: {
        dream: "",
        userName: "",
        userSex: "男",
        userBirthTime: "",
        time: "刚睡",
        course: "混乱",
        concern: "运势",
        channel: this.$route.query.apkChannel,
      },
      realDream: "", // 选择的梦境
      showmjResult: false,
      dataList: [],
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2200, 12, 12),
      currentDate: new Date(1995, 5, 15, 12, 0, 0),
      showPopup: false,
      isSelected: false,
    };
  },
  created() {
    api.getList().then((res) => {
      if (res.find((item) => item.status === 0)) {
        this.showTips = true;
      }
    });
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    jumpTpUrl(type) {
      const query = location.href.replace('&isCallBack=0', '').split('?');
      if (type === 'zjr') {
        location.href = `https://${document.domain}/rmzejiri/main?isCallBack=1&${query[1]}`;
      }
      if (type === 'zwds') {
        location.href = `https://${document.domain}/rmbdaziweidoushu1/main?isCallBack=1&${query[1]}`;
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      return val;
    },
    onHandleGz(item) {
      this.params.concern = item;
    },
    jumpToAnalysis() {
      this.$router.push("/analysis");
    },
    jumpToOrder() {
      this.$router.push("/order");
    },
    parseDate(str) {
      if (str.match(/^\d{4}[-/\s+]\d{1,2}[-/\s+]\d{1,2}$/)) {
        return new Date(str.replace(/[-/\s+]/i, "/"));
      } else if (str.match(/^\d{8}$/)) {
        return new Date(
          str.substring(0, 4) +
            "/" +
            str.substring(4, 6) +
            "/" +
            str.substring(6)
        );
      } else {
        return "时间转换发生错误！";
      }
    },
    getAgeByBrithday(birthday) {
      var age = -1;
      var today = new Date();
      var todayYear = today.getFullYear();
      var todayMonth = today.getMonth() + 1;
      var todayDay = today.getDate();
      let birthdayYear = birthday.getFullYear();
      let birthdayMonth = birthday.getMonth();
      let birthdayDay = birthday.getDate();
      // if (todayYear - birthdayYear < 0) {
      //   alert("出生日期选择错误!");
      // } else {
      if (todayMonth * 1 - birthdayMonth * 1 < 0) {
        age = todayYear * 1 - birthdayYear * 1 - 1;
      } else {
        if (todayDay - birthdayDay >= 0) {
          //alert(thisDay+'-'+brithd+"_ddd");
          age = todayYear * 1 - birthdayYear * 1;
        } else {
          age = todayYear * 1 - birthdayYear * 1 - 1;
        }
      }
      // }
      return age * 1;
    },
    oneiromancy() {
      // if (!this.isSelected) {
      //   this.$toast('请先输入梦境');
      //   return;
      // }
      if (this.isToBack) {
        window.scrollTo(0, 0);
        return;
      }
      for (let key in this.params) {
        if (!this.params[key]) {
          if (key === "dream") {
            this.$toast("请先输入梦境");
            return;
          }
          if (key === "userName") {
            this.$toast("请先输入姓名");
            return;
          }
          if (key === "userBirthTime") {
            this.$toast("请先选择生日");
            return;
          }
        }
      }
      if (this.currentDate && this.getAgeByBrithday(this.currentDate) < 18) {
        this.$toast("未满18岁禁止使用！");
        return;
      }
      if (!this.selectProtocol) {
        this.$toast("请先勾选用户隐私协议");
        return;
      }
      const inner = ["根", "苗", "花", "果"];
      const outer = [
        "壬",
        "葵",
        "甲",
        "乙",
        "丙",
        "丁",
        "戊",
        "己",
        "庚",
        "辛",
      ];
      const center = [
        "子",
        "未",
        "申",
        "酉",
        "戊",
        "亥",
        "子",
        "丑",
        "寅",
        "卯",
        "辰",
        "巳",
      ];
      this.params.horoscope = `${inner[Math.floor(Math.random() * 3)]}、${
        center[Math.floor(Math.random() * 9)]
      }、${outer[Math.floor(Math.random() * 10)]}`;
      this.params.isCallBack = this.$route.query.isCallBack || 0;
      api.createOrder(this.params).then((res) => {
        if (res.code === 0) {
          this.$router.push({
            path: "/process",
            query: {
              odd: res.data.odd,
            },
          });
          localStorage.setItem("odd", res.data.odd);
          localStorage.setItem("price", res.data.price);
        } else {
          this.$toast(res.message);
        }
      });
    },
    getDreamReslut() {
      let param = {
        dream: this.params.dream,
      };
      api.dreamResult(param).then((res) => {
        this.showmjResult = true;
        if (res.result && res.result.length) {
          for (let i = 0; i < res.result.length; i++) {
            res.result[i].newDes = res.result[i].title
              .split(this.params.dream)
              .join(`<span style="color:red">${this.params.dream}</span>`);
          }
          this.dataList = res.result;
        } else {
          this.dataList = [];
          this.params.dream = "";
          this.$forceUpdate();
        }
      });
    },
    onCheckedItem(item) {
      // this.params.dream = item.title;
      console.log(item);
      this.showmjResult = false;
      this.isSelected = true;
    },
    onChange(index) {
      this.current = index;
    },
    scrollingDynamics() {
      this.$nextTick(() => {
        const showArea = document.querySelector(".mydt-notice");
        showArea.scrollTop = 0;
        function f() {
          let hhh =
            showArea.scrollHeight -
            document.getElementById("mydtContent").clientHeight;
          if (showArea.scrollTop >= hhh) {
            showArea.scrollTop = 0;
          } else {
            const speed = 1;
            showArea.scrollTop += speed;
          }
          requestAnimationFrame(f);
        }
        requestAnimationFrame(f);
      });
    },
    confirmDateTime() {
      this.params.userBirthTime = this.currentDate.Format("yyyy年MM月dd日hh时");
      this.showPopup = false;
    },
    cancelDateTime() {
      this.showPopup = false;
    },
  },
  mounted() {
    const showArea = document.querySelector(".notice");
    showArea.scrollLeft = 0;
    function f() {
      if (
        showArea.scrollLeft >=
        showArea.scrollWidth - document.getElementById("app").clientWidth
      ) {
        showArea.scrollLeft = 0;
      } else {
        const speed = 1;
        showArea.scrollLeft += speed;
      }
      requestAnimationFrame(f);
    }
    requestAnimationFrame(f);
    this.scrollingDynamics();
    const _this = this;
    window.addEventListener("scroll", () => {
      if (
        (document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop) >
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight)
      ) {
        document.getElementsByClassName("submit")[0].style.position = "fixed";
        document.getElementsByClassName("submit")[0].style.bottom = "20px";
        document.getElementsByClassName("submit")[0].style.zIndex = 9;
        _this.isToBack = true;
      } else {
        document.getElementsByClassName("submit")[0].style.position =
          "relative";
        document.getElementsByClassName("submit")[0].style.bottom = "0";
        _this.isToBack = false;
      }
    });
  },
};
</script>
<style lang="less">
.van-overlay {
  max-width: 460px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}
.el-radio {
  margin-right: 0.1rem;
  .el-radio__label {
    padding-left: 0.1rem;
  }
  .el-radio__input {
    height: 14px;
    .el-radio__inner {
      background: unset;
    }
  }
  .el-avatar,
  .el-cascader-panel,
  .el-radio,
  .el-radio--medium.is-bordered .el-radio__label,
  .el-radio__label {
    font-size: px2rem(26);
    color: #6a470f;
  }
}
</style>
<style lang="less" scoped>
@plugin '../styles/var/px2rem.js';
.main {
  background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/main-bg.png")
    repeat;
  background-size: contain;
  position: relative;
  font-size: px2rem(26);
  padding-bottom: px2rem(200);
  // height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }
  .banner {
    height: 5rem;
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/banner.png")
      no-repeat 0.3rem;
    background-size: calc(100% - 0.3rem) 100%;
    img {
      margin-left: 0.2rem;
    }
  }
  .mjzl {
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/mjzl_bg.png")
      no-repeat;
    background-size: 100% 100%;
    height: 10.2rem;
    margin: px2rem(-24) 0.34rem 0;
    padding-top: px2rem(96);
    .title {
      width: 40%;
      display: block;
      margin: 0 auto px2rem(55);
    }
    .form {
      margin: 0 px2rem(66);
      p {
        padding-bottom: px2rem(19);
        margin-bottom: px2rem(19);
        border-bottom: 1px solid #bdb6aa;
        position: relative;
        &.gz {
          border-bottom: none;
        }
        label {
          font-size: px2rem(32);
          color: #6a470f;
          font-weight: 500;
          display: inline-block;
          width: px2rem(100);
        }
        input {
          width: 70%;
          font-size: px2rem(26);
          color: #6a470f;
          background: transparent;
          &::-webkit-input-placeholder {
            color: #ddceb5;
          }
        }
        i {
          display: block;
          position: absolute;
          width: px2rem(34);
          height: px2rem(20);
          right: 0;
          bottom: px2rem(14);
          background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/xiala.png")
            no-repeat;
          background-size: 100% 100%;
        }
        button {
          position: absolute;
          right: 0;
          bottom: px2rem(14);
          height: px2rem(50);
          color: #ffffff;
          border: unset;
          font-size: px2rem(26);
          background: unset;
          span {
            display: inline-block;
            width: px2rem(72);
            height: px2rem(48);
            line-height: px2rem(48);
            color: #ccb385;
            font-size: px2rem(26);
            border-radius: 15px 0 0 15px;
            border: px2rem(2) solid #ccb385;
            &.active {
              background: linear-gradient(0deg, #603312, #ce853e);
              color: #fff;
            }
          }
        }
        .list {
          width: calc(100% - px2rem(100));
          display: inline-block;
          .cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            span {
              display: block;
              width: px2rem(105);
              height: px2rem(43);
              line-height: px2rem(43);
              text-align: center;
              background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/select.png")
                no-repeat;
              background-size: 100% 100%;
              color: #6a470f;
              &.active {
                background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/selected.png")
                  no-repeat;
                background-size: 100% 100%;
                color: #fff;
              }
            }
          }
        }
      }
      .sj,
      .gc {
        display: flex;
        .uncheck-radio {
          border: px2rem(1) solid #a6967a;
          border-radius: 100%;
          width: px2rem(24);
          height: px2rem(24);
          cursor: pointer;
          box-sizing: border-box;
          &.active {
            background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/redio_checked.png")
              no-repeat;
            background-size: 100% 100%;
            border: unset;
          }
        }
        /deep/ .van-radio-group {
          display: flex;
        }
        /deep/ .van-radio {
          // width: px2rem(24);
        }
        /deep/ .van-radio__icon--round {
          display: flex;
          align-items: center;
        }
        /deep/ .van-radio__label {
          margin: 0 px2rem(16);
          font-size: px2rem(26);
          color: #6a470f;
          font-weight: 500;
        }
      }
    }
  }
  // ------------------------------------------
  .notice {
    max-width: 460px;
    width: 100%;
    overflow: hidden;
    background: #999;
    padding: 4px 0;
    height: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    .content {
      opacity: 0.7;
      font-size: 14px;
      color: #fff;
      word-break: keep-all;
      white-space: nowrap;
      position: absolute;
      line-height: 1.5;
    }
  }
  .tips {
    display: flex;
    align-items: center;
    height: 0.85rem;
    background-color: #ffd77f;
    padding: 0 0.25rem;
    justify-content: space-between;
    .order-desc {
      display: flex;
      font-size: 0.28rem;
      color: #333;
      align-items: center;
    }
    .go-order {
      width: 1.57rem;
      height: 0.58rem;
      border-radius: 0.32rem;
      text-align: center;
      line-height: 0.58rem;
      font-size: 0.26rem;
      font-weight: 500;
      background-color: #2b2d5d;
      color: #fff;
      margin-right: 0.25rem;
    }
    .close-tips {
      color: #896c35;
      font-size: 0.24rem;
    }
  }
  .commit-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ttips {
      color: #896c35;
      font-size: px2rem(24);
      margin-top: px2rem(20);
    }
    @keyframes analysis {
      from {
        transform: scale(1.4);
      }
      to {
        transform: scale(1.2);
      }
    }
    > span {
      display: flex;
      height: px2rem(31);
      align-items: center;
      color: #79461c;
      font-size: px2rem(28);
      a {
        border-bottom: 1px solid;
        a {
          border-bottom: none;
        }
      }
      i {
        display: block;
        width: 0.43rem;
        height: 0.43rem;
        margin-right: 0.21rem;
        background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/unchecked.png")
          no-repeat;
        background-size: 100% 100%;
        &.active {
          background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/checked.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    > button {
      border: unset;
      margin-top: px2rem(48);
      background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/oneiromancy.png")
        no-repeat;
      background-size: 100% 100%;
      width: px2rem(522.5);
      height: px2rem(78.2);
      font-size: px2rem(36);
      color: #ffedcb;
      animation: analysis 1.2s linear infinite;
      animation-direction: alternate;
    }
  }
  .jptj {
    margin: px2rem(60) 0.34rem 0;
    .title {
      width: px2rem(297);
      display: block;
      margin: px2rem(50) auto;
    }
    .cont {
      display: flex;
      justify-content: space-between;
      img {
        width: px2rem(330);
        height: px2rem(120);
      }
    }
  }
  .ys {
    margin: px2rem(60) 0.34rem 0;
    .title {
      width: px2rem(321);
      display: block;
      margin: px2rem(50) auto;
    }
    .cont {
      width: 100%;
    }
  }
  .xyjm {
    margin: px2rem(45) 0.34rem 0;
    .title {
      width: px2rem(425);
      display: block;
      margin: 0 auto;
    }
    span {
      display: inline-block;
      color: #804c24;
      font-size: px2rem(28);
      font-weight: Bold;
      margin-top: px2rem(35);
      padding-left: px2rem(170);
    }
    .jm {
      width: 100%;
      display: block;
      margin: px2rem(30) auto;
    }
    .jms {
      width: 100%;
      margin: px2rem(30) auto;
      display: flex;
      justify-content: space-between;
      > div {
        text-align: center;
        img {
          width: px2rem(206);
          height: px2rem(173);
          margin-bottom: px2rem(30);
        }
        p {
          font-size: px2rem(32);
          font-weight: 400;
          color: #754521;
        }
      }
    }
  }
  .mjkr {
    margin: px2rem(45) 0.34rem 0;
    .mjzz {
      width: 100%;
      margin-top: px2rem(30);
    }
    .title {
      width: px2rem(425);
      display: block;
      margin: 0 auto;
    }
    span {
      display: inline-block;
      color: #804c24;
      font-size: px2rem(28);
      font-weight: Bold;
      margin-top: px2rem(35);
      padding-left: px2rem(170);
    }
    .mjkr-img {
      margin-top: px2rem(40);
      display: flex;
      justify-content: space-between;
      > div {
        flex: 1;
        &:first-child {
          margin-right: px2rem(40);
        }
      }
      img {
        margin-bottom: px2rem(30);
        width: 100%;
        height: px2rem(188);
      }
      p {
        font-size: px2rem(28);
        font-weight: 400;
        color: #754521;
        line-height: 1.5;
      }
    }
  }
  .jmdyy {
    background: url(https://qiniu.zhiyunys.com/zgjiemeng/main/jmdyy_bg.png)
      no-repeat;
    background-size: 100% 100%;
    height: px2rem(902);
    margin: px2rem(45) 0.34rem 0;
    padding-top: px2rem(46);
    > .title {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
    > span {
      display: inline-block;
      color: #804c24;
      font-size: px2rem(28);
      font-weight: Bold;
      margin-top: px2rem(35);
      padding-left: px2rem(170);
    }
    .jmdyy-img {
      width: px2rem(558);
      height: px2rem(188);
      margin-left: px2rem(67);
      margin-top: px2rem(29);
      &:first-child {
        margin-top: px2rem(54);
      }
    }
  }
  .dszrjm {
    background: url(https://qiniu.zhiyunys.com/zgjiemeng/main/dszrjm_bg.png)
      no-repeat;
    background-size: 100% 100%;
    height: px2rem(549);
    margin: px2rem(28) 0.34rem 0;
    padding-top: px2rem(46);
    > .title {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
    > span {
      display: inline-block;
      color: #804c24;
      font-size: px2rem(28);
      font-weight: Bold;
      margin-top: px2rem(35);
      padding-left: px2rem(149);
    }
    > p {
      color: #804c24;
      font-size: px2rem(28);
      line-height: px2rem(48);
      width: px2rem(531);
      height: px2rem(220);
      margin: px2rem(45) auto 0;
      font-family: PingFangSC-Regular;
    }
  }
  .bzjm {
    background: url(https://qiniu.zhiyunys.com/zgjiemeng/main/bzjm_bg.png)
      no-repeat;
    background-size: 100% 100%;
    height: px2rem(553.8);
    margin: px2rem(8) px2rem(57) 0;
  }
  .bzjm_con {
    background: url(https://qiniu.zhiyunys.com/zgjiemeng/main/bzjm_con.png)
      no-repeat;
    background-size: 100% 100%;
    width: px2rem(708);
    height: px2rem(382);
    margin: px2rem(33) 0.34rem 0;
    padding-top: px2rem(58);
    p {
      color: #804c24;
      font-size: px2rem(28);
      line-height: px2rem(48);
      font-family: PingFangSC-Regular;
      margin: 0 auto;
      width: px2rem(615);
      height: px2rem(267);
    }
  }
  .mzzsal {
    background: url(https://qiniu.zhiyunys.com/zgjiemeng/main/mzzsal_bg.png)
      no-repeat;
    background-size: 100% 100%;
    height: px2rem(835);
    margin: px2rem(28) 0.34rem 0;
    padding-top: px2rem(46);
    > .title {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
    > span {
      display: inline-block;
      color: #804c24;
      font-size: px2rem(28);
      font-weight: Bold;
      margin-top: px2rem(35);
      padding-left: px2rem(136);
    }
    .van-swipe {
      width: px2rem(556.9);
      height: px2rem(585.6);
      margin: px2rem(40) auto 0;
      .van-swipe-item {
        width: px2rem(556.9);
        height: px2rem(503.6);
        img {
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
        }
      }
      .custom-indicator {
        position: absolute;
        bottom: 12px;
        left: 50%;
        display: flex;
        transform: translateX(-50%);
        .custom-indicator-item {
          width: px2rem(28);
          height: px2rem(8);
          margin-right: px2rem(20);
          background: rgba(127, 76, 36, 0.35);
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background: #7f4c24;
          }
        }
      }
    }
  }
  .mydt {
    background: url(https://qiniu.zhiyunys.com/zgjiemeng/main/mzzsal_bg.png)
      no-repeat;
    background-size: 100% 100%;
    height: px2rem(835);
    margin: px2rem(28) 0.34rem 0;
    padding-top: px2rem(46);
    > .title {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
  }
  .mydt {
    background: url(https://qiniu.zhiyunys.com/zgjiemeng/main/mydt_bg.png)
      no-repeat;
    background-size: 100% 100%;
    height: px2rem(703);
    margin: px2rem(30) 0.34rem 0;
    padding-top: px2rem(46);
    > .title {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
    .mydt-notice {
      width: px2rem(557);
      height: px2rem(466);
      border: 1px solid #f0e9e5;
      margin: px2rem(76) auto 0;
      overflow: hidden;
      .mydt-content {
        margin: px2rem(41) auto px2rem(29);
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .mydt-content-item {
        width: px2rem(506);
        height: px2rem(119);
        border-bottom: px2rem(1) dashed #754521;
        display: flex;
        flex-direction: column;
        label {
          height: px2rem(40);
          font-size: px2rem(26);
          font-family: PingFang SC;
          font-weight: 600;
          color: #754521;
          line-height: px2rem(40);
        }
        span {
          font-size: px2rem(28);
          font-family: PingFang SC;
          font-weight: 400;
          color: #946a4b;
          line-height: px2rem(40);
          margin-top: px2rem(14);
        }
      }
    }
  }
  .company {
    color: #754521;
    text-align: center;
    margin: px2rem(30) 0.34rem 0;
    font-size: px2rem(26);
    padding-bottom: px2rem(60);
  }
  .van-datetime-picker {
    position: fixed;
    bottom: 0;
    width: 100vw;
    max-width: 460px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px 15px 0 0;
    /deep/ .van-picker__toolbar {
      border-bottom: 1px solid #ddd8d3;
    }
    /deep/ .van-picker__cancel {
      color: #b3a498;
    }
    /deep/ .van-picker__confirm {
      color: #6a470f;
    }
    /deep/ .van-picker-column__item {
      background: #fff;
      color: #666666;
      font-size: px2rem(28);
    }
    /deep/ .van-picker-column__item--selected {
      background: #e9e9e9;
      color: #666666;
    }
    /deep/ .van-picker__mask {
      background: unset;
    }
  }
}
</style>