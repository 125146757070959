<template>
  <div>
    <div class="fix-order">
      <img
        src="https://qiniu.zhiyunys.com/img/order-check.png"
        @click="$router.push('/order')"
      />
    </div>
    <div class="process" v-if="!showResult">
      <div class="animation">
        <div id="outer" class="outer"></div>
        <div id="center" class="center"></div>
        <div id="inner" class="inner"></div>
        <div id="core" class="core"></div>
      </div>
      <div class="title">{{ info.userName }}的八字梦境解析</div>
      <div class="desc bz">
        <div class="list" v-for="(item, index) in descList" :key="index">
          <div class="circle">
            <div class="circle-inner">{{ item.first }}</div>
          </div>
          <div class="second">{{ item.second }}</div>
          <div class="third">{{ item.third }}</div>
        </div>
      </div>
      <div class="desc name">
        <p class="desc-list">
          姓名:<span>{{ info.userName }}</span>
        </p>
        <p class="desc-list">
          性别:<span>({{ info.userSex }})</span>
        </p>
        <p class="desc-list">
          生日:<span>{{ info.usergBirthTime }}</span>
        </p>
      </div>
      <div class="info">
        <div class="tab">
          <div
            :class="`tab-list ${tabActive === item ? 'active' : ''}`"
            v-for="(item, index) in tabs"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div
          class="content"
          :style="`visibility: ${tabActive === tabs[0] ? 'visible' : 'hidden'}`"
        >
          <div class="content-list">
            <p>正在录入命主信息</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
          <div class="content-list">
            <p>正在生成八字</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
        </div>
        <div
          class="content"
          :style="`visibility: ${tabActive === tabs[1] ? 'visible' : 'hidden'}`"
        >
          <div class="content-list">
            <p>正在分析你的梦境</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
          <div class="content-list">
            <p>正在计算梦境格局</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
          <div class="content-list">
            <p>正在解析生成梦境内容</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
        </div>
        <div
          class="content"
          :style="`visibility: ${tabActive === tabs[2] ? 'visible' : 'hidden'}`"
        >
          <div class="content-list">
            <p>正在生成八字梦境报告</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
          <div class="content-list">
            <p>正在计算梦境报告</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
          <div class="content-list">
            <p>正在生成梦境报告</p>
            <div class="bar">
              <span class="bar-list"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="result" v-if="showResult">
      <div class="pay-return" v-if="showSale" @click.stop="hideSale">
        <div class="pay-return-content">
          <h4>恭喜获得红包！</h4>
          <div class="pay-return-price">
            <p><span>20</span>元</p>
            <p>订单金额 &gt; 20元可用</p>
            <p>可在购买测算时进行抵扣</p>
          </div>
          <div class="pay-return-time">
            <div class="timer">
              <span>{{hourSale}}</span>:<span>{{minuteSale}}</span>:<span>{{secondSale}}</span>
            </div>
            后失效
          </div>
          <button class="pay-return-btn" @click="payBySale">立即领取</button>
        </div>
      </div>
      <img
        class="top"
        src="https://qiniu.zhiyunys.com/zgjiemeng/result_banner.png"
      />
      <div class="info">
        <img
          class="title"
          src="https://qiniu.zhiyunys.com/zgjiemeng/result_info_title.png"
        />
        <p>
          梦境:<span>{{ info.dream }}</span>
        </p>
        <p>
          姓名:<span>{{ info.userName }}({{ info.userSex }})</span>
        </p>
        <p>
          生日:<span>{{ info.usergBirthTime }}</span>
        </p>
        <p>
          时间:<span>{{ info.time }}</span>
        </p>
        <p>
          过程:<span>{{ info.course }}</span>
        </p>
        <p>
          关注:<span>{{ info.concern }}</span>
        </p>
        <p>
          八字:<span>{{ info.horoscope }}</span>
        </p>
      </div>
      <img class="payed-img" src="../assets/img/improve/payed.png" />
      <div class="jyj" style="display: block !important">
        <img
          class="title"
          src="https://qiniu.zhiyunys.com/zgjiemeng/result_jyj_title.png"
        />
        <div class="desc">测算项目：专业解梦</div>
        <div class="jyj-list price">
          优惠价:<span>{{ totalPrice }}<span>元</span></span>
        </div>
        <div class="jyj-list init">(原价：168.00元)</div>
        <div class="jyj-list time">
          距优惠结束:
          <div class="time-out">
            <p>{{ hour }}</p>
            :
            <p>{{ minute }}</p>
            :
            <p>{{ second }}</p>
          </div>
        </div>
        <div class="jyj-list way">
          支付方式:
          <div class="ali" @click="payWay = 1">
            <div class="pic"></div>
            <p>支付宝</p>
            <el-radio v-model="payWay" :label="1"></el-radio>
          </div>
          <div class="wx" @click="payWay = 2">
            <div class="pic"></div>
            <p>微信</p>
            <el-radio v-model="payWay" :label="2"></el-radio>
          </div>
        </div>
      </div>
      <p class="tips">支付成功后，需要返回当前页面查看结果</p>
      <div class="submit"></div>
      <div class="submit" @click="submit">立即查看解梦结果报告</div>
      <div class="res">
        <img
          class="title"
          src="https://qiniu.zhiyunys.com/zgjiemeng/result_title.png"
        />
        <p>
          梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看
        </p>
        <p>
          梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看梦境请付费查看
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/apis/order";
export default {
  data() {
    return {
      showResult: false,
      payWay: 2,
      animation: 12,
      tabActive: "基础命盘",
      tabs: ["基础命盘", "梦境解析", "生成报告"],
      inner: ["根", "苗", "花", "果"],
      outer: ["壬", "葵", "甲", "乙", "丙", "丁", "戊", "己", "庚", "辛"],
      center: [
        "子",
        "未",
        "申",
        "酉",
        "戊",
        "亥",
        "子",
        "丑",
        "寅",
        "卯",
        "辰",
        "巳",
      ],
      descList: [],
      hour: "00",
      minute: "00",
      second: "00",
      hourSale: "00",
      minuteSale: "00",
      secondSale: "00",
      info: {},
      price: localStorage.getItem("price"),
      timer: null,
      timerSale: null,
      showSale: false,
      first: true,
      redPacket: 0,
    };
  },
  created() {
    api
      .getOrder({
        odd: localStorage.getItem("odd"),
        orderType: "周公解梦",
      })
      .then((res) => {
        if (!res.message) {
          this.info = res[0] || {};
        }
      });
  },
  computed: {
      totalPrice() {
          const result = (this.price - this.redPacket).toFixed(2); // 保留两位小数
          return Number(result).toString();
      }
  },
  methods: {
    hideSale() {
      this.showSale = false;
    },
    payBySale() {
      localStorage.setItem('redPacket' + this.$route.query.odd, 20);
      this.redPacket = 20;
      this.submit();
      this.noFirst();
    },
    back() {
      if (this.first) {
        this.showSale = true;
        this.countDownSale();
      }
    },
    noFirst() {
      this.first = false;
    },
    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        return true; // 移动端
      } else {
        return false; // PC端
      }
    },
    alipay() {
      localStorage.setItem("payway", "ali");
      api
        .alipay1({
          type: this.isMobile() ? "mobile" : "pc",
          odd: localStorage.getItem("odd"),
          redPacket: this.redPacket,
          // url: `https://${document.domain}/zhougong/process?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // url: `https://${document.domain}/zhougong/process?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            url: location.href.replace(this.$route.path, '/process'), // 神马
          Channel: this.$route.query.apkChannel,
          // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            domain: location.href.replace(this.$route.path, '/payResult'), // 神马
        })
        .then((res) => {
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res;
          document.body.appendChild(div);
          document.getElementById("alipaysubmit").submit();
        });
    },
    wxpay() {
      localStorage.setItem("payway", "wx");
      if (this.isMobile()) {
        api
          .wxpay1({
            type: "mobile",
            odd: localStorage.getItem("odd"),
          redPacket: this.redPacket,
            // url: `https://${document.domain}/zhougong/process?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // url: `https://${document.domain}/zhougong/process?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            url: location.href.replace(this.$route.path, '/process'), // 神马
            Channel: this.$route.query.apkChannel,
            // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            domain: location.href.replace(this.$route.path, '/payResult'), // 神马
          })
          .then((res) => {
            location.href = res.code_url;
          });
      } else {
        api
          .wxpay1({
            type: "pc",
            odd: localStorage.getItem("odd"),
          redPacket: this.redPacket,
            // url: `https://${document.domain}/zhougong/process?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // url: `https://${document.domain}/zhougong/process?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            url: location.href.replace(this.$route.path, '/process'), // 神马
            Channel: this.$route.query.apkChannel,
            // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // domain: `https://${document.domain}/zhougong/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            domain: location.href.replace(this.$route.path, '/payResult'), // 神马
          })
          .then((res) => {
            this.$router.push({
              path: "/wxPay",
              query: {
                info: JSON.stringify(res),
              },
            });
          });
      }
    },
    submit() {
      if (this.payWay === 1) {
        this.alipay();
      } else {
        this.wxpay();
      }
    },
    countDownSale() {
      // 预计时间
      const _this = this;
      var expectTime =
        new Date().getTime() +
        10 * 60 * 1000;
      var nowTime = new Date().getTime(); //当前时间总的毫秒数
      var inputTime = expectTime; //用户输入时间总的毫秒数
      var times = (inputTime - nowTime) / 10; //times是剩余时间总的毫秒数
      this.timerSale = setInterval(function () {
        times--;
        // var d = (d = parseInt(times / 60 / 60 / 24))
        // d = d < 10 ? "0" + d : d
        var h = parseInt((times / 100 / 60) % 60);
        h = h < 10 ? "0" + h : h;
        var m = parseInt(times / 100 % 60);
        m = m < 10 ? "0" + m : m;
        var s = parseInt(times % 100);
        // console.log(h, m, s, " ===");
        if (s > 0) {
          s = s < 10 ? "0" + s : s;
          _this.hourSale = h;
          _this.minuteSale = m;
          _this.secondSale = (s+ '').slice(0, 2);
        }
        // if (s < 0) {
        //   clearInterval(this.timer);
        // }
      }, 10);
    },
    countDown() {
      // 预计时间
      const _this = this;
      var expectTime = new Date().getTime() +
        8 * 60 * 60 * 1000;
      var nowTime = new Date().getTime(); //当前时间总的毫秒数
      var inputTime = expectTime; //用户输入时间总的毫秒数
      var times = (inputTime - nowTime) / 1000; //times是剩余时间总的毫秒数
      this.timer = setInterval(function () {
        times--;
        // var d = (d = parseInt(times / 60 / 60 / 24))
        // d = d < 10 ? "0" + d : d
        var h = parseInt((times / 60 / 60) % 24);
        h = h < 10 ? "0" + h : h;
        var m = parseInt((times / 60) % 60);
        m = m < 10 ? "0" + m : m;
        var s = parseInt(times % 60);
        // console.log(h, m, s, " ===");
        if (s > 0) {
          s = s < 10 ? "0" + s : s;
          _this.hour = h;
          _this.minute = m;
          _this.second = s;
        }
        // if (s < 0) {
        //   clearInterval(this.timer);
        // }
      }, 1000);
    },
  },
  destroyed() {
    clearInterval(this.timerSale);
    window.removeEventListener('popstate', this.back, false);
  },
  mounted() {
    console.log('redPacket' + this.$route.query.odd)
    if (localStorage.getItem('redPacket' + this.$route.query.odd)) {
      this.redPacket = localStorage.getItem('redPacket' + this.$route.query.odd)
    }
    // 跳转页面
    setTimeout(() => {
      this.showResult = true;
      if (window.history && window.history.pushState && this.first) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.back, false);
      } else {
        window.removeEventListener('popstate', this.back, false);
      }
      this.$nextTick(() => {
        this.countDown();
      });
    }, this.animation * 1000);

    const obj = {
      inner1: Math.floor(Math.random() * 3),
      center1: Math.floor(Math.random() * 9),
      outer1: Math.floor(Math.random() * 11),
      inner2: Math.floor(Math.random() * 3),
      center2: Math.floor(Math.random() * 9),
      outer2: Math.floor(Math.random() * 11),
      inner3: Math.floor(Math.random() * 3),
      center3: Math.floor(Math.random() * 9),
      outer3: Math.floor(Math.random() * 11),
      inner4: Math.floor(Math.random() * 3),
      center4: Math.floor(Math.random() * 9),
      outer4: Math.floor(Math.random() * 11),
    };
    this.descList = [
      {
        first: this.inner[obj.inner1],
        second: this.center[obj.center1],
        third: this.outer[obj.outer1],
      },
      {
        first: this.inner[obj.inner2],
        second: this.center[obj.center2],
        third: this.outer[obj.outer2],
      },
      {
        first: this.inner[obj.inner3],
        second: this.center[obj.center3],
        third: this.outer[obj.outer3],
      },
      {
        first: this.inner[obj.inner4],
        second: this.center[obj.center4],
        third: this.outer[obj.outer4],
      },
    ];
    this.$nextTick(() => {
      // 进度条动画
      this.tabs.forEach((item, index) => {
        setTimeout(() => {
          this.tabActive = item;
        }, (this.animation / Array.from(document.getElementsByClassName("bar-list")).length) * (index <= 1 ? 2 * index : 3 * index - 1) * 1000);
      });
      Array.from(document.getElementsByClassName("bar-list")).forEach(
        (item, index) => {
          item.style.transition = `width linear ${
            this.animation /
            Array.from(document.getElementsByClassName("bar-list")).length
          }s`;
          setTimeout(() => {
            item.style.width = "100%";
          }, index * ((this.animation / Array.from(document.getElementsByClassName("bar-list")).length) * 1000));
        }
      );
      // 转盘动画
      const tempTime = (this.animation - 3) / 4;
      const innerElement = document.getElementById("inner");
      const centerElement = document.getElementById("center");
      const outerElement = document.getElementById("outer");
      [1, 2, 3, 4].forEach((index) => {
        innerElement.style.transition = `all ${tempTime}s linear`;
        centerElement.style.transition = `all ${tempTime}s linear`;
        outerElement.style.transition = `all ${tempTime}s linear`;
        setTimeout(() => {
          innerElement.style.transform = `translateX(-50%) rotate(${
            index % 2 === 0 ? "-" : "+"
          }${
            obj[`inner${index}`] === 0 ? 360 : (360 / 4) * obj[`inner${index}`]
          }deg)`;
          // console.log(
          //   innerElement.style.transform,
          //   "innerElement.style.transform"
          // );
        }, (index - 1) * (tempTime + 1) * 1000);
        setTimeout(() => {
          centerElement.style.transform = `translateX(-50%) rotate(${
            index % 2 === 0 ? "+" : "-"
          }${
            obj[`inner${index}`] === 0
              ? 360
              : (360 / 10) * obj[`center${index}`]
          }deg)`;
          // console.log(
          //   centerElement.style.transform,
          //   "centerElement.style.transform"
          // );
        }, (index - 1) * (tempTime + 1) * 1000);
        setTimeout(() => {
          outerElement.style.transform = `translateX(-50%) rotate(${
            index % 2 === 0 ? "-" : "+"
          }${
            obj[`inner${index}`] === 0 ? 360 : (360 / 12) * obj[`outer${index}`]
          }deg)`;
          // console.log(
          //   outerElement.style.transform,
          //   "outerElement.style.transform"
          // );
        }, (index - 1) * (tempTime + 1) * 1000);
      });
      // 文字动画
      Array.from(document.getElementsByClassName("list")).forEach(
        (item, index) => {
          setTimeout(() => {
            item.style.opacity = 1;
          }, index * 1000);
        }
      );
      setTimeout(() => {
        document.getElementsByClassName("bz")[0].style.display = "none";
        document.getElementsByClassName("name")[0].style.display = "block";
      }, (Array.from(document.getElementsByClassName("list")).length + 0.5) * 1000);
      Array.from(document.getElementsByClassName("desc-list")).forEach(
        (item, index) => {
          setTimeout(() => {
            item.style.opacity = 1;
          }, (index * 1 + (Array.from(document.getElementsByClassName("list")).length + 0.5)) * 1000);
        }
      );
    });
    // window.addEventListener("scroll", () => {
    //   if (
    //     (document.documentElement.scrollTop ||
    //       window.pageYOffset ||
    //       document.body.scrollTop) >
    //     (window.innerHeight ||
    //       document.documentElement.clientHeight ||
    //       document.body.clientHeight)
    //   ) {
    //     document.getElementsByClassName("submit")[0].style.position = "fixed";
    //     document.getElementsByClassName("submit")[0].style.bottom = "20px";
    //   } else {
    //     document.getElementsByClassName("submit")[0].style.position =
    //       "relative";
    //     document.getElementsByClassName("submit")[0].style.bottom = "0";
    //   }
    // });
  },
};
</script>
<style lang="less">
@plugin '../styles/var/px2rem.js';
.el-radio {
  margin-right: 0.1rem;
  .el-radio__label {
    padding-left: 0.1rem;
  }
  .el-radio__input {
    width: px2rem(28);
    height: px2rem(28);
    .el-radio__inner {
      background: unset;
      border-color: #a6967a;
      &::after {
        content: "";
        display: block;
        width: px2rem(13) !important;
        height: px2rem(13) !important;
        border-radius: 50%;
        background: #a6967a;
      }
    }
  }
  .el-avatar,
  .el-cascader-panel,
  .el-radio,
  .el-radio--medium.is-bordered .el-radio__label,
  .el-radio__label {
    font-size: 0.187rem;
    color: #6a470f;
  }
}
</style>
<style lang="less" scoped>
@plugin '../styles/var/px2rem.js';
.result {
  background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/main-bg.png");
  background-size: contain;
  background-size: contain;
  background-size: contain;
  position: relative;
  font-size: 0.187rem;
  overflow: auto;
  padding: px2rem(37) px2rem(33) 0;
  .pay-return {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 11;
    .pay-return-content {
      position: fixed;
      width: px2rem(534);
      height: px2rem(812);
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: url(../assets/img/result_return.png) 0 0 no-repeat;
      background-size: contain;
      padding: px2rem(50) 0 0;
      text-align: center;
    }
    h4 {
      margin-top: px2rem(20);
      font-size: px2rem(40);
      color: #db8014;
    }
    .pay-return-price {
      margin-top: px2rem(40);
      p:first-child {
        font-size: px2rem(60);
        color: #df062a;
      }
      p:nth-child(2) {
        width: px2rem(325);
        height: px2rem(44);
        background: rgba(255, 141, 48, 0.24);
        border: 1px solid rgba(188, 78, 14, 0.24);
        margin: px2rem(20) auto 0;
        line-height: px2rem(44);
        color: #bd5815;
        font-size: px2rem(30);
      }
      p:nth-child(3) {
        color: #bc4e0e;
        font-size: px2rem(30);
        line-height: px2rem(44);
        opacity: 0.5;
      }
    }
    .pay-return-time {
      margin-top: px2rem(120);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: px2rem(30);
      span {
        display: inline-block;
        vertical-align: middle;
        width: px2rem(46);
        height: px2rem(46);
        border: 1px solid #ff4e50;
        background: #ca1929;
        text-align: center;
        line-height: px2rem(46);
        margin: 0 px2rem(15);
      }
    }
    .pay-return-btn {
      margin-top: px2rem(60);
      width: px2rem(340);
      height: px2rem(94);
      border-radius: px2rem(40);
      -webkit-box-shadow: 0 0.4688rem 0.5625rem 0 rgba(138, 0, 12, 0.26),
        inset 0 0.2813rem 0.5625rem 0 #fff;
      box-shadow: 0 0.4688rem 0.5625rem 0 rgba(138, 0, 12, 0.26),
        inset 0 0.2813rem 0.5625rem 0 #fff;
      background-color: #fb9d3f;
      border: none;
      font-size: px2rem(40);
      color: #6c3000;
      font-weight: 700;
    }
  }
  .top {
    // width: px2rem(656);
    height: px2rem(234);
  }
  .info {
    height: px2rem(648);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/result_info.png")
      no-repeat;
    background-size: 100% 100%;
    padding: px2rem(94) px2rem(67) 0;
    .title {
      height: px2rem(54);
      display: block;
      margin: 0 auto px2rem(46);
    }
    p {
      margin-bottom: px2rem(43);
      font-size: px2rem(32);
      color: #6a470f;
      span {
        font-size: px2rem(26);
        margin-left: px2rem(18);
        color: rgba(106, 71, 15, 0.9);
      }
    }
  }
  .payed-img {
    width: 100%;
    margin: px2rem(30) 0 0;
  }
  .jyj {
    height: px2rem(541);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/result_jyj.png")
      no-repeat;
    background-size: 100% 100%;
    margin-top: px2rem(30);
    margin-bottom: px2rem(44);
    padding: px2rem(46) px2rem(67);
    &-list {
      color: #804c24;
      font-size: px2rem(28);
      margin-bottom: px2rem(31);
      span {
        margin-left: px2rem(18);
      }
      &.price {
        span {
          font-size: px2rem(60);
          color: #ff2c2c;
          font-weight: 600;
          span {
            font-size: px2rem(26);
            margin-left: 0;
          }
        }
      }
      &.init {
        color: rgba(128, 76, 36, 0.43);
        text-decoration: line-through;
      }
      &.time {
        display: flex;
        .time-out {
          margin-left: px2rem(18);
          font-size: px2rem(36);
          color: #ff2c2c;
          font-weight: 600;
          display: flex;
          text-align: center;
        }
      }
      &.way {
        display: flex;
        /deep/ .el-radio__label {
          display: none;
        }
        .ali,
        .wx {
          margin-left: px2rem(18);
          font-size: px2rem(26);
          color: rgba(106, 71, 15, 0.9);
          text-align: center;
          margin-top: px2rem(-10);
          .pic {
            width: px2rem(66);
            height: px2rem(66);
            // border-radius: 50%;
            margin: 0 auto px2rem(13);
          }
          p {
            margin-bottom: px2rem(15);
          }
        }
        .ali {
          .pic {
            background: url("https://qiniu.zhiyunys.com/zgjiemeng/ali.png")
              no-repeat;
            background-size: contain;
          }
        }
        .wx {
          .pic {
            background: url("https://qiniu.zhiyunys.com/zgjiemeng/wx.png")
              no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .title {
      height: px2rem(54);
      display: block;
      margin: 0 auto px2rem(35);
    }
    .desc {
      text-align: center;
      font-size: px2rem(28);
      color: #804c24;
      margin-bottom: px2rem(47);
    }
  }
  .tips {
    color: #79461c;
    font-size: px2rem(28);
    text-align: center;
    margin-bottom: px2rem(22);
  }
  @keyframes analysis {
    from {
      transform: scale(1.4);
    }
    to {
      transform: scale(1.2);
    }
  }
  .submit {
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/main/oneiromancy.png")
      no-repeat;
    background-size: 100% 100%;
    width: px2rem(522.5);
    height: px2rem(78.2);
    line-height: px2rem(78.2);
    text-align: center;
    margin: px2rem(70) auto px2rem(39);
    font-size: px2rem(36);
    font-weight: 600;
    color: #ffedcb;
    cursor: pointer;
    animation: analysis 1.2s linear infinite;
    animation-direction: alternate;
  }
  .res {
    height: px2rem(652);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/result_bg.png")
      no-repeat;
    background-size: 100% 100%;
    margin-bottom: px2rem(44);
    padding: px2rem(46) px2rem(67);
    .title {
      height: px2rem(54);
      display: block;
      margin: 0 auto px2rem(46);
    }
    p {
      line-height: 2;
      font-size: px2rem(26);
      // color: rgba(106, 71, 15, 0.9);
      // filter: blur(px2rem(5));
      color: transparent;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
<style lang="less">
@plugin '../styles/var/px2rem.js';
.process {
  height: 100vh;
  // background: #f2f0ea;
  background: url("https://qiniu.zhiyunys.com/zgjiemeng/process/process_bg.png");
  background-size: contain;
  padding-top: px2rem(17);
  .animation {
    width: 100%;
    height: calc(100vh - px2rem(341));
    position: absolute;
    left: 0;
    top: px2rem(341);
    z-index: 999;
    background: rgba(0, 0, 0, 0.4);
    .outer {
      width: px2rem(600);
      height: px2rem(600);
      background: url("https://qiniu.zhiyunys.com/zgjiemeng/case2/outer.png")
        no-repeat;
      background-size: contain;
      top: px2rem(133);
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      position: absolute;
    }
    .center {
      width: px2rem(460);
      height: px2rem(460);
      background: url("https://qiniu.zhiyunys.com/zgjiemeng/case2/center.png")
        no-repeat;
      background-size: contain;
      top: px2rem(203);
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      position: absolute;
    }
    .inner {
      width: px2rem(318);
      height: px2rem(318);
      background: url("https://qiniu.zhiyunys.com/zgjiemeng/case2/inner.png")
        no-repeat;
      background-size: contain;
      top: px2rem(274);
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      position: absolute;
    }
    .core {
      width: px2rem(167);
      height: px2rem(167);
      background: url("https://qiniu.zhiyunys.com/zgjiemeng/case2/core.png")
        no-repeat;
      background-size: contain;
      top: px2rem(351);
      left: 50%;
      transform: translateX(-50%);
      z-index: 4;
      position: absolute;
    }
  }
  .title {
    width: px2rem(433);
    height: px2rem(97);
    line-height: px2rem(97);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/process/process_title.png")
      no-repeat;
    background-size: 100% 100%;
    font-size: px2rem(32);
    color: #754521;
    text-align: center;
    // margin: 0.23rem auto 0.53rem;
    margin: 0 auto px2rem(20);
  }
  .desc {
    width: px2rem(451);
    margin: auto;
    display: flex;
    justify-content: space-between;
    font-size: px2rem(28);
    color: #ae6d34;
    &.name {
      width: px2rem(361);
      color: #6a470f;
      font-size: px2rem(32);
      display: block;
      padding-left: px2rem(60);
      display: none;
      p {
        margin-bottom: px2rem(45);
        opacity: 0;
        span {
          font-size: px2rem(26);
          margin-left: px2rem(18);
          color: rgba(106, 71, 15, 0.9);
        }
      }
    }
    .list {
      text-align: center;
      transition: all linear 2s;
      opacity: 0;
      .circle {
        border: 1px solid rgba(152, 124, 103, 0.5);
        border-radius: 50%;
        width: px2rem(79);
        height: px2rem(79);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: px2rem(16);
        &-inner {
          border: 1px solid rgba(152, 124, 103, 0.5);
          border-radius: 50%;
          width: px2rem(67);
          height: px2rem(67);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
        }
      }
      .second {
        margin-bottom: px2rem(16);
      }
    }
  }
  .info {
    height: px2rem(752);
    background: url("https://qiniu.zhiyunys.com/zgjiemeng/process/process_border.png")
      no-repeat;
    background-size: 100% 100%;
    margin: px2rem(28) px2rem(23);
    padding: px2rem(42) px2rem(34) 0;
    position: relative;
    .tab {
      display: flex;
      justify-content: space-around;
      font-size: px2rem(28);
      padding-bottom: px2rem(18);
      border-bottom: 1px solid rgba(152, 124, 103, 0.5);
      margin-bottom: px2rem(42);
      &-list {
        width: px2rem(189);
        height: px2rem(53);
        line-height: px2rem(53);
        text-align: center;
        color: rgba(152, 124, 103, 0.67);
        &.active {
          background: url("https://qiniu.zhiyunys.com/zgjiemeng/process/process_selected.png")
            no-repeat;
          background-size: 100% 100%;
          font-weight: 500;
          color: #ae6d34;
          text-align: left;
          padding-left: px2rem(18);
        }
      }
    }
    .content {
      color: #6a470f;
      font-size: px2rem(28);
      position: absolute;
      width: calc(100% - px2rem(34) * 2);
      &-list {
        p {
          margin-bottom: px2rem(22);
        }
        .bar {
          margin-bottom: px2rem(22);
          height: px2rem(16);
          background: #e6dbd1;
          border-radius: 8px;
          &-list {
            width: 0;
            height: px2rem(16);
            display: block;
            background: linear-gradient(-89deg, #af7b55, #dd9c5e);
            border-radius: 8px;
          }
        }
      }
    }
  }
}
</style>